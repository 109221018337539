.homeContainer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	height: 100%;
	background: #090a0a;
	margin-top: 3.31rem;

	@media screen and (min-width: 730px) {
		padding: 0 2.33rem;
		margin-top: 3.31rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 2.87rem;
		margin-top: 3.81rem;
	}
	@media screen and (min-width: 1200px) {
		padding: 0 5.25rem ;
		margin-top: 4.75rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 8.25rem;
		margin-top: 4.75rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 16.25rem ;
		margin-top: 4rem;
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	margin-bottom: 6rem;
	gap: 2.62rem;
	padding: 0 0.63rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 4.07rem;
		gap: 3.3rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 5rem;
		gap: 3.99rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 6.3rem;
		gap: 5.04rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 7.5rem;
		gap: 6rem;
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	padding:0  1.24rem;

	@media screen and (min-width: 730px) {
		align-items: center;
		gap: 0.14rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.17rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.25rem;
	}
}
.heading {
	font-size: 2rem;
	color: #fff;
	font-weight: 700;

	span {
		background: linear-gradient(90deg, #4359f6 64.26%, #8458ff 80.21%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.subHeading {
	font-size: 1.09rem;
	color: #8f919e;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.25rem;
	}
}
.headerImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.78;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.78;
		}
	}

	video{
		border-radius: .8rem;
		width: 100%;

		@media screen and (min-width: 730px) {
			width: 85%;
		}
	}
}

.messageContentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 5rem;
	margin-bottom: 6rem;

	@media screen and (min-width: 730px) {
		margin-top: 4rem;
		margin-bottom: 5.76rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: 5rem;
		margin-bottom: 7.07rem;
	}

	@media screen and (min-width: 1400px) {
		margin-top: 7rem;
		margin-bottom: 8.93rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: 7.5rem;
		margin-bottom: 10.62rem;
	}
}

.downloadContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: #4359f6;
	gap: 2.31rem;
	padding: 1.19rem 0.87rem 2.76rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		padding: 0.9625rem 3.63956rem 0.9925rem 3.36875rem;
		gap: 8.55rem;

		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		padding: 1.165rem 4.40531rem 1.20131rem 4.0625rem;
		gap: 6.26656rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1.68rem;
		padding: 1.47rem 5.55863rem 1.51581rem 5.145rem;
		gap: 12.49rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 2rem;
		padding: 1.75rem 6.61744rem 1.80456rem 6.125rem;
		gap: 15.23rem;
	}
}
.downloadLeftContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	gap: 1rem;
	padding: 0 0.49rem 0 1.69rem;

	@media screen and (min-width: 730px) {
		gap: 1.42rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.7rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}
}
.downloadHeader {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}

.download_Os_container {
	display: flex;
	flex-direction: column;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 2.17rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4rem;
	}
}

.downloadBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.31rem;

	@media screen and (min-width: 730px) {
		align-items: center;
		gap: 1.1rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.downloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
	padding: 0.69rem 0.96rem;
	border-radius: 1.71rem;

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}

.osLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1.1rem;
	// gap: 2.5rem;

	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.osLogos {
	height: 2rem;

	@media screen and (min-width: 730px) {
		height: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		height: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2rem;
	}
}


.downloadImgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;

	img {
		width: 100%;
		// aspect-ratio: ;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 0.47;
		}
	}

	@media screen and (min-width: 730px) {
		width: 54%;
	}
	@media screen and (min-width: 1200px) {
		width: 40%;
	}
	@media screen and (min-width: 1400px) {
		width: 54%;
	}
}

.messageFeatures_Container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 2.03rem;
	padding: 0 1.5rem;
	text-align: center;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}
.messageFeatures_header {
	font-size: 1.875rem;
	font-weight: 700;
	text-align: center;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.messageFeatures1_header {
	width: 90%;
	text-align: center;

	@media screen and (min-width: 730px) {
		width: 100%;
	}
}
.features_content {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto;
	padding-bottom: 1.08rem;
	gap: 1rem;

	@media screen and (min-width: 730px) {
		gap: 0;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		gap: 0.55rem .86rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem 1.04rem;
		padding-bottom: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem 1.31rem;
		padding-bottom: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap:  1rem 1.58rem;
		padding-bottom: 2rem;
	}
}

.messageFeatureContainer{
	display: flex;
	width: 100%;
}
.message_feature {
	display: flex;
	flex-direction: row;
	// align-items: center;
	gap: 0.55rem;
	border-radius: 1rem;
	padding: 0.55rem;

	@media screen and (min-width: 730px) {
		gap: 0.55rem;
		padding: 0.55rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem;
		padding: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem;
		padding: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
		padding: 1rem;
	}
}
.featureIcons {
	height: 1.42rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
		height: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		height: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2.625rem;
	}
}
.featureDescription {
	font-size: 0.825rem;
	font-weight: 600;
	color: #ffffff;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 0.825rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.5rem;
	}
}
.featureDescriptionText {
	font-size: 0.625rem;
	font-weight: 400;
	color: #ffffff;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 0.625rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.94rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.125rem;
	}
}
.descriptionTextContainer {
	display: flex;
	flex-direction: column;
	gap: 0.55rem;

	@media screen and (min-width: 730px) {
		gap: 0.55rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
	}
}
.illustrationContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2.06rem;
	// padding: 0 0.63rem;

	@media screen and (min-width: 730px) {
		flex-direction: column;
		gap: 2.06rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}
.protectedMessageContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2rem;
	// padding: 0 0.63rem;

	@media screen and (min-width: 730px) {
		flex-direction: column;
		gap: 3.3rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 3.99rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.04rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}
}
.protectedMessageImg {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 0.85;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.55;
		}
	}
}

.messagePrivacyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.06rem;

	@media screen and (min-width: 730px) {
		gap: 2.03rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.secureDeviceimg {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #000;
	border-radius: 1.1rem;
	gap: 2.01rem;
	padding: 1.45rem 0 3.64rem;

	img {
		width: 100%;
		aspect-ratio: 1.23;
		@media screen and (min-width: 730px) {
			aspect-ratio: 1.88;
		}
	}

	@media screen and (min-width: 730px) {
		padding: 1.375rem 4.74375rem 1.52669rem 4.70938rem;

		gap: 1.48294rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 2.1rem 7.245rem 2.33169rem 7.1925rem;
		gap: 2.34669rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 2.1rem 7.245rem 2.33169rem 7.1925rem;
		gap: 2.34669rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 2.5rem 8.625rem 2.77581rem 8.5625rem;
    	gap: 2.77581rem;
	}
}

.secureDeviceText {
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.1rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2rem;
	}
}

.secureNetworkContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		flex-direction: column;
		gap: 2.06rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.networkTextImgcontainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2.62rem;
	background-color: #000;
	border-radius: 1.1rem;
	padding: 1rem;
	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 0;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
	}
	@media screen and (min-width: 1400px) {
		border-radius: 1.68rem;
	}
	@media screen and (min-width: 1700px) {
		border-radius: 2rem;
	}
}
.netwokTextContainer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: 730px) {
		width: 50%;
	}
}


.illustrationSubHeader {
	font-size: 1.125rem;
	font-weight: 700;
	color: #ffffff;
	// text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 1.125rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
	span {
		color: #737784;
	}
}
.illustrationSubHeader3 {
	width: 80%;

	@media screen and (min-width: 730px) {
		width: 50%;
	}
}

.networkImgContainer {
	display: flex;
	flex-direction: row;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1;
	}
	@media screen and (min-width: 730px) {
		width: 50%;
	}
}

.messageTextImgContainer {
	display: flex;
	flex-direction: column;
	gap: 2.53rem;
	padding: 1rem 1.02669rem 0.699rem 1.3125rem;
	background-color: #000;
	border-radius: 1.1rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: .83rem;
		border-radius: 1.1rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 1rem;
		border-radius: 1.33rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1.26rem;
		border-radius: 1.68rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.5rem;
		border-radius: 2rem;
	}
}

.confidentialityImg {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 0.76;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.49;
		}
	}
}

.confidentialityText {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 30%;
	}
}
.illustrationHeader {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.setUsApartContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.44rem 1rem 0.22rem;
	gap: 2.56rem;
	background: #000;
	border-radius: 1.1rem;

	@media screen and (min-width: 730px) {
		background: none;
		padding: 0;
		gap: .79rem;
	}

	@media screen and (min-width: 1000px) {
		gap: .96rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.4rem;
	}
}
.setApartHeader {
	display: flex;
	flex-direction: column;
	// padding: 0 1.69rem;
	gap: 0.4rem;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.49rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.61rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.73rem;
	}
}
.setApartImg {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		aspect-ratio: 0.54;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.38;
		}
	}
}
.messageFeatureSubHeader {
	font-size: 1.25rem;
	font-weight: 500;
	color: #8f919e;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
}
.footerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: #4359f6;
	padding: 1.1875rem 1.9875rem 5.69rem 1.9375rem;
	gap: 2.56rem;
	// gap: 4.8111rem;

	@media screen and (min-width: 730px) {
		padding: 0.9875rem 3rem 0.98513rem 3.9875rem;
		gap: 8.25rem;
		flex-direction: row-reverse;
		width: 100%;
		justify-content: space-between;
		border-radius: 1.08rem;
		margin-bottom: 2.66rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		// padding: 0 0.45rem;
		padding: 1.19519rem 3.23rem 1.19244rem 4.82644rem;
		gap: 9.9375rem;
		margin-bottom: 4.01rem;
	}

	@media screen and (min-width: 1200px) {
		width: 85%;
	}
	@media screen and (min-width: 1400px) {
		padding: 1.50813rem 3.4825rem 1.50463rem 6.09rem;
		border-radius: 1.68rem;
		gap: 13.375rem;
		margin-bottom: 6.04rem;
		width: 100%;
	}

	@media screen and (min-width: 1700px) {
		padding: 1.8rem 7.3rem 1.8rem 7.3rem;
		gap: 15.3rem;
		border-radius: 2rem;
		margin-bottom: 6.3rem;
	}
}
.footerLeftContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	// justify-content: center;
	gap: 1rem;
	width: 100%;
	z-index: 2;

	@media screen and (min-width: 730px) {
		align-items: flex-start;
		gap: 1.44rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.75rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}
}
.footerText_header {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}
.footerImgContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2.56rem;

	.footerImg {
		display: flex;
		width: 50%;

		img {
			width: 100%;
			aspect-ratio: 0.47;
			z-index: 0;
	
		}
		@media screen and (min-width: 730px) {
			width: 100%;
		}
		@media screen and (min-width: 1000px) {
		}
		@media screen and (min-width: 1200px) {
			width: 100%;
		}
		@media screen and (min-width: 1400px) {
			width: 100%;
		}
	}
	@media screen and (min-width: 730px) {
		width: 54%;
		gap: 0;
	}

}
.footerLogoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.1rem;

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.footerDownload_container {
	display: flex;
	flex-direction: row;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.download_Qr_scanner {
	display: flex;
	flex-direction: column;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.footerBtn_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}